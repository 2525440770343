import axios from "axios";
import API from "../config/api";
import baseURLConfig from "../config/envConfig";

export async function getResponse(url: string, id?: string) {
    const requestUrl = id ? `${url}/${id}` : url;
    const response = await API.get(requestUrl);
    return response.data;
}

export const fetchListingData = async (queryParams: any) => {
    let queryString: string = "?";
    // queryParams['areaStudy'] = '8'
    const specialParams = ['gclid', 'msclkid', 'fbclid', 'ttclid'];
    let sub3 = specialParams
        .filter(param => queryParams[param]) 
        .map(param => {
            const value = queryParams[param];
            delete queryParams[param]; 
            return value;
        })
        .join('_');

    // Assign sub3 back to queryParams if it's not empty
    if (sub3) {
        queryParams['sub3'] = sub3;
    }
    if(queryParams['t1'] || queryParams['t2'] || queryParams['t3']){
        delete queryParams['t1']
        delete queryParams['t2']
        delete queryParams['t3']
    }

    Object.entries(queryParams).forEach(([key, value]: [string, any]) => {
        let encodedValue = String(value);
        // Decode and then encode the values for sub1, sub2, and sub3
        if (['sub1', 'sub2', 'sub3'].includes(key)) {
            encodedValue = encodeURIComponent(decodeURIComponent(encodedValue));
        }
        queryString += `${key}=${encodedValue}&`;
    });
    
    if (queryParams['mProgram']) {
        const searchURL = baseURLConfig.listingURL;
        const response = await axios.get(`${searchURL}${queryString.slice(0, -1)}`);
        return response.data;
    }
    return []
};

export async function getSEOConfigResponse(url: string, id?: string) {
    const requestUrl = id ? `${url}/${id}` : url;
    const response = await API.get(requestUrl);
    return response.data;
}

export const ProgramResponse = async (url: string) => {
    const decodedNewURL = decodeURI(url).replace('&', '%26')
    const response = await API.get(decodedNewURL)
    return response.data
}

export const removeSpecialCharacters = (inputString: any) => {
    var pattern = /[\[\]{}%^&|\\'`#]/g;
    var result = inputString.replace(pattern, ' ');
    result = result.trim();
    result = encodeURIComponent(result);
    result = result.replace(/%20/g, '');

    return result;
}

export const preparedListingQuery = async (queryParams: any) => {
    let queryString: string = "?";
    // if (queryParams['gclid']) {
    //     queryParams['sub3'] = queryParams['gclid'];
    //     delete queryParams['gclid'];
    // }
    const specialParams = ['gclid', 'msclkid', 'fbclid', 'ttclid'];
    let sub3 = specialParams
        .filter(param => queryParams[param]) 
        .map(param => {
            const value = queryParams[param];
            delete queryParams[param]; 
            return value;
        })
        .join(',');
    // Assign sub3 back to queryParams if it's not empty
    if (sub3) {
        queryParams['sub3'] = sub3;
    }
    if (queryParams['t1'] || queryParams['t2'] || queryParams['t3']) {
        delete queryParams['t1'];
        delete queryParams['t2'];
        delete queryParams['t3'];
    }
    Object.entries(queryParams).forEach(([key, value]: [string, any]) => {
        const cleanedValue = removeSpecialCharacters(String(value));
        queryString += `${key}=${cleanedValue}&`;
        // queryString += `${key}=${String(value)}&`
    });
    if (queryParams['areaStudy']) {
        const searchURL = baseURLConfig.listingURL;
        return `${searchURL}${queryString.slice(0, -1)}`;
    }
    return '';
};