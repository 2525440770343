import {
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormLabel,
  FormHelperText,
  Radio,
  Container,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import brokenImage from '../../assets/images/broken-image.png';
import axios from 'axios';
import { WebContext } from '../../contextProvider/webContextProvider';

interface FormValues {
  [key: string]: string | boolean | number | any[];
}

const DynamicForm = ({ formItem, leadFormData }: any) => {
  const { selectedOffers, setSelectedOffers } = useContext(WebContext);
  const [selectedCampus, setSelectedCampus] = useState<any>('');
  const [selectedProgram, setSelectedProgram] = useState<any>('');
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues: FormValues = formItem.details?.form_fields.reduce(
    (initialValues: FormValues, field: any) => {
      if (field.form_field_type_name === 'text-field') {
        if (field.name === 'address2') {
          initialValues[field.name] = leadFormData?.userLocation?.userAddress;
        } else if (field.name === 'phone2') {
          initialValues[field.name] =
            leadFormData?.userInformation?.userPhoneNumber;
        } else {
          initialValues[field.name] = '';
        }
      } else if (field.form_field_type_name === 'checkbox') {
        initialValues[field.name] = false; // Set your initial value for checkboxes
      } else if (field.name === 'tcpa_consent') {
        initialValues[field.name] = false;
      } else if (
        field.form_field_type_name === 'generic-list-with-radio' ||
        field.form_field_type_name === 'generic-dropdown'
      ) {
        initialValues[field.name] = field.form_list_items[0]?.value; // Set initial value for radio groups
      } else if (field.name === 'phone2') {
        initialValues[field.name] =
          leadFormData?.userInformation?.userPhoneNumber;
      }

      return initialValues;
    },
    {}
  );

  // Dynamically build validation schema based on form fields
  const validationSchema = yup.object().shape(
    formItem.details?.form_fields.reduce((schema: any, field: any) => {
      if (field.name === 'tcpa_consent') {
        schema[field.name] = yup
          .boolean()
          .oneOf([true], 'You must agree to the terms and conditions');
      }
      if (
        field.required &&
        field.form_field_type_name === 'text-field' &&
        field.name !== 'address2' &&
        field.name !== 'phone2'
      ) {
        schema[field.name] = yup
          .string()
          .required(`${field.label_text} is required`);
      }
      if (field.required && field.form_field_type_name === 'generic-dropdown') {
        schema[field.name] = yup.string().required(`This field is required`);
      }
      if (
        field.required &&
        field.form_field_type_name === 'checkbox' &&
        field.name !== 'tcpa_consent'
      ) {
        schema[field.name] = yup
          .boolean()
          .oneOf([true], `${field.label_text} is required`);
      }
      return schema;
    }, {})
  );

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
    dirty,
    setFieldValue,
  } = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values: any, { resetForm }) => {
      // Handle form submission here

      const payload: any = {
        'lead_education[campus_id]': selectedCampus?.id,
        'lead_education[program_id]': selectedProgram?.id,
        result_id: formItem?.result_id,
        'lead_background[teaching_license]': 'teaching-license-no',
        'lead_background[rn_license]': 'registered-nurse-no',
      };

      formItem?.details?.form_fields.forEach((field: any) => {
        const fieldName = field.name;
        const postKey = field.post_key;
        const fieldValue = values[fieldName];

        if (values.hasOwnProperty(fieldName)) {
          if (field.form_field_type_name === 'checkbox') {
            // If checkbox is checked, set the value to "Yes"
            payload[postKey] = fieldValue ? 'Yes' : 'No';
          } else {
            // For other fields, directly assign the value
            payload[postKey] = fieldValue;
          }
        }
      });

      // Use payload in your API call or as needed
      console.warn('value', values);
      console.warn('payload', payload);
      if (
        !selectedOffers.some(
          (item: any) => item.result_id === formItem?.result_id
        )
      ) {
        setSelectedOffers([...selectedOffers, formItem]);
      }
      const apiResponse: any = await axios({
        method: 'POST',
        url: 'https://hooks.zapier.com/hooks/catch/6973915/399m4qc/',
        params: payload,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setIsSuccess(true);
      // Reset the form after successful submission
      //   resetForm();
    },
  });

  useEffect(() => {
    // console.log('formItem', formItem);
    setSelectedCampus(formItem.details?.campuses[0]);
    setSelectedProgram(formItem.details?.campuses[0]?.programs[0]);
  }, []);

  return (
    <Container>
      <form onSubmit={handleSubmit} key={formItem.result_id}>
        <Card className="lead_forms" key={formItem.id}>
          <Box
            className="lead_forms_left_content"
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <CardMedia
              component="img"
              image={formItem.details?.logo_url || brokenImage}
              alt=""
              sx={{
                width: formItem.details?.logo_url ? '100%' : '80px ! important',
              }}
            />
          </Box>
          <CardContent className="lead_forms_right_content">
            <Typography component="div" variant="h5" fontWeight="600" mt={1}>
              {formItem.details?.external_name}
            </Typography>
            {!isSuccess && (
              <>
                <Grid container spacing={3} mt={1} sx={{ textAlign: 'left' }}>
                  {formItem.details?.form_fields.some(
                    (item: any) => item.name === 'campus_id'
                  ) && (
                    <>
                      <Grid item xs={12} md={6} className="pt-10">
                        <FormControl
                          // variant="filled"
                          fullWidth
                          sx={{ width: '100%', background: '#ffffff' }}
                          size="small"
                        >
                          <InputLabel>Campus</InputLabel>
                          <Select
                            value={selectedCampus}
                            onChange={event => {
                              setSelectedCampus(event?.target?.value);
                              setSelectedProgram(
                                event?.target?.value.programs[0]
                              );
                            }}
                            name="campus_id"
                            label="Campus"
                          >
                            {formItem.details?.campuses.map((i: any) => (
                              <MenuItem value={i} key={i.id}>
                                {i.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} className="pt-10">
                        <FormControl
                          // variant="filled"
                          fullWidth
                          sx={{ width: '100%', background: '#ffffff' }}
                          size="small"
                        >
                          <InputLabel>Programs</InputLabel>
                          <Select
                            value={selectedProgram}
                            onChange={event => {
                              setSelectedProgram(event?.target?.value);
                            }}
                            name="program_id"
                            label="Programs"
                          >
                            {selectedCampus?.programs?.map((i: any) => (
                              <MenuItem value={i} key={i.id}>
                                {i.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  {formItem.details?.form_fields
                    .filter(
                      (i: any) => i.form_field_type_name === 'generic-dropdown'
                    )
                    .map((i: any) => (
                      <Grid item xs={12} md={6} className="pt-10">
                        <FormControl
                          // variant="filled"
                          fullWidth
                          sx={{ width: '100%', background: '#ffffff' }}
                          size="small"
                        >
                          <InputLabel>{i?.label_text}</InputLabel>
                          <Select
                            value={values[i?.name]}
                            onChange={event => {
                              handleChange(event);
                              setFieldValue(i?.name, event.target.value);
                            }}
                            name={i?.name}
                            label={i?.label_text}
                          >
                            {i?.form_list_items?.map((j: any) => (
                              <MenuItem value={j?.value} key={j?.value}>
                                {j?.display}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* {touched[i.name] ||  Boolean(errors[i.name]) ? (
                  <Box color="#db2424" sx={{ textAlign: 'left' }}>
                    {errors[i.name]}{' '}
                  </Box>
                ) : null} */}
                      </Grid>
                    ))}

                  {formItem.details?.form_fields
                    .filter(
                      (i: any) =>
                        i.form_field_type_name === 'text-field' &&
                        i.name !== 'address2' &&
                        i.name !== 'phone2'
                    )
                    .map((i: any) => (
                      <Grid item xs={12} md={6} className="pt-10">
                        <TextField
                          fullWidth
                          sx={{ background: '#ffffff' }}
                          label={i.label_text}
                          name={i.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values[i.name]}
                          size="small"
                        />
                        {/* {touched[i.name] ||  Boolean(errors[i.name]) ? (
                  <Box color="#db2424" sx={{ textAlign: 'left' }}>
                    {errors[i.name]}{' '}
                  </Box>
                ) : null} */}
                      </Grid>
                    ))}
                  {formItem.details?.form_fields
                    .filter(
                      (i: any) =>
                        i.form_field_type_name === 'generic-list-with-radio'
                    )
                    .map((i: any) => (
                      <Grid item xs={12} className="pt-10">
                        <FormControl>
                          <FormLabel id={i?.name}>{i?.label_text}</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby={i?.name}
                            name={i?.name}
                            // defaultValue={i?.form_list_items[0]?.value}
                            value={values[i?.name]}
                            onChange={event => {
                              console.log('event', event);
                              handleChange(event);
                              setFieldValue(i?.name, event.target.value);
                            }}
                          >
                            {i?.form_list_items?.map((item: any) => (
                              <FormControlLabel
                                key={item?.value}
                                value={item?.value}
                                control={<Radio />}
                                label={item?.display}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                        {/* {touched[i.name] ||  Boolean(errors[i.name]) ? (
                  <Box color="#db2424" sx={{ textAlign: 'left' }}>
                    {errors[i.name]}{' '}
                  </Box>
                ) : null} */}
                      </Grid>
                    ))}

                  {formItem.details?.form_fields
                    .filter(
                      (i: any) =>
                        i.name !== 'tcpa_consent' &&
                        i.form_field_type_name === 'checkbox'
                    )
                    .map((i: any) => (
                      <Grid
                        item
                        xs={12}
                        sx={{ textAlign: 'left' }}
                        className="pt-10"
                      >
                        <FormControlLabel
                          className="lead-checkbox"
                          control={
                            <Checkbox
                              key={i.id}
                              name={i.name}
                              checked={!!values[i.name]}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleChange(event);
                                setFieldValue(i.name, event.target.checked);
                              }}
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: '14px' }}>
                              {i.label_text}
                            </Typography>
                          }
                        />
                        {/* {touched[i.name] && errors[i.name] ? (
                  <Box color="#db2424" sx={{ textAlign: 'left' }}>
                    {errors[i.name]}
                  </Box>
                ) : null} */}
                      </Grid>
                    ))}

                  {formItem.details?.form_fields
                    .filter((i: any) => i.name === 'tcpa_consent')
                    .map((i: any) => (
                      <Grid
                        item
                        xs={12}
                        sx={{ textAlign: 'left' }}
                        className="pt-10"
                      >
                        <FormControlLabel
                          className="lead-checkbox"
                          control={
                            <Checkbox
                              key={i.id}
                              name={i.name}
                              checked={!!values[i.name]}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                handleChange(event);
                                setFieldValue(i.name, event.target.checked);
                              }}
                            />
                          }
                          label={
                            <Typography sx={{ fontSize: '14px' }}>
                              {i.label_text}
                            </Typography>
                          }
                        />
                        {/* {touched[i.name] && errors[i.name] ? (
                  <Box color="#db2424" sx={{ textAlign: 'left' }}>
                    {errors[i.name]}
                  </Box>
                ) : null} */}
                      </Grid>
                    ))}
                </Grid>
                <Box textAlign="center" mt={2}>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    type="submit"
                    sx={{
                      textTransform: 'none',
                      fontSize: '18px',
                      borderRadius: '8px',
                      minWidth: '150px',
                    }}
                    disabled={!(dirty && isValid)}
                  >
                    Submit Request
                  </Button>
                </Box>
              </>
            )}
            {isSuccess && (
              <Typography variant="body1" color="success" mb={2}>
                Form submitted successfully!
              </Typography>
            )}
          </CardContent>
        </Card>
      </form>
    </Container>
  );
};

export default DynamicForm;
