import FormIndex from "../components/LeadFormSteps/FormIndex";
import LiberalArtsListing from "../Container";

export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "General Liberal Arts",
        path: "/liberalart/generalliberalarts",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "Creative Writing & English",
        path: "/liberalart/creativewriting&english",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "Communications",
        path: "/liberalart/communications",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "History",
        path: "/liberalart/history",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "Religious Studies",
        path: "/liberalart/religiousstudies",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "Socialogy",
        path: "/liberalart/socialogy",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "Humanities",
        path: "/liberalart/humanities",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "Political Science",
        path: "/liberalart/politicalscience",
        exact: true,
        component: LiberalArtsListing
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: LiberalArtsListing
    }
]
