import {
  Box,
  Typography,
  Card,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
} from '@mui/material';
// import { cardProperty } from '../../constants/styles';
import { useContext, useEffect, useState } from 'react';
// import { TAreaStudy } from '../../types/common';
import { LeadFormAreaStudyTitle } from '../../constants/leadForms';
import { WebContext } from '../../contextProvider/webContextProvider';

const LeadFormAreaStudy = ({
  onNext,
  leadFormData,
  setLeadFormData,
  areaStudyData,
  mProgramList,
  getMProgramList
}: any) => {
  const { webContent } = useContext(WebContext);
  const [isError, setIsError] = useState<any>(false);
  // useEffect(() => {
  //   leadAreaStudyResponse();
  // }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);



  const handleChangeAreaStudy = (event: any) => {
    const selectedAreaStudy = event.target.value;
    setLeadFormData({ ...leadFormData, areaStudy: selectedAreaStudy });
    getMProgramList(selectedAreaStudy);
  };
  const handleChangeMProgram = (event: any) => {
    setIsError(false);
    const mProgram = event.target.value;
    setLeadFormData({ ...leadFormData, mProgram: mProgram });
   // onNext();
  };
  return (
    <Box>
      <Box textAlign="center">
        <Typography
          component="h5"
          fontSize="30px"
          maxWidth="600px"
          lineHeight="1.3"
          mx="auto"
          fontWeight="799"
          mt={2}
          mb={[4,3,2]}
        >
          {LeadFormAreaStudyTitle}
        </Typography>
      </Box>
      <Box
        display="grid"
        justifyContent="center"
        flexWrap="wrap"
        gap="40px"
        maxWidth="510px"
        mx="auto"
        mt={[5,0]}
      >
        <FormControl sx={{ width: '300px', display: 'flex', m: '8px auto 0' }} disabled={webContent?.defaultData?.template==2}>
          <InputLabel>Area of Study</InputLabel>
          <Select
            sx={{ backgroundColor: '#ffffff !important' }}
            label="Area of Study"
            value={leadFormData?.areaStudy}
            onChange={handleChangeAreaStudy}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {areaStudyData.map((val: any) => (
              <MenuItem value={val?.areaStudy} key={val?.areaStudy}>
                {val?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {Boolean(mProgramList.length) && (
          <div>
            <FormControl
              sx={{ width: '300px', display: 'flex' }}
              error={isError}
            >
              <InputLabel>Select Specialization</InputLabel>
              <Select
                sx={{ backgroundColor: '#ffffff !important' }}
                label="Select Specialization"
                value={leadFormData?.mProgram}
                onChange={handleChangeMProgram}
                MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
              >
                {mProgramList.map((val: any) => (
                  <MenuItem value={val?.id} key={val?.id}>
                    {val?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {isError ? (
              <Box color="#db2424" pl={.5} mt={.5}>Please Select Specialization</Box>
            ) : null}
          </div>
        )}
          <Box textAlign="center">
          <Button
            /// type="submit"
            variant="contained"
            //size="large"
            sx={{
              textTransform: 'none',
              fontSize: '18px',
              borderRadius: '8px',
              minWidth: '150px',
              backgroundColor: webContent?.defaultData?.primaryColor,
              '&:hover': {
                backgroundColor: webContent?.defaultData?.primaryColor,
              },
            }}
            onClick={() => {
              leadFormData?.mProgram ? onNext() : setIsError(true);
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LeadFormAreaStudy;
