export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]


export const mPrograms: any =
    [{ "text": "General Liberal Arts", "value": "800" },
    { "text": "Creative Writing & English", "value": "801" },
    { "text": "Communications", "value": "802" },
    { "text": "History", "value": "803" },
    { "text": "Religious Studies", "value": "804" },
    { "text": "Socialogy", "value": "805" },
    { "text": "Humanities", "value": "806" },
    { "text": "Political Science", "value": "807" }
    ]

